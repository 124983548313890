export const environment = {
    production: true,
    title: 'kolumbus.citybike.cloud',
    mcs_template: 'CITYBIKEK',
    main_url: 'https://api.kolumbus.citybike.cloud',
    api_url: 'https://api.kolumbus.citybike.cloud/api',
    insight_api_url: 'https://api.insight.citybike.cloud/api',
    s3_image_url: 'https://mcs-citybike.s3-eu-west-1.amazonaws.com/',
    logo_title: 'CityBike - Kolumbus',
    map_center_position: [5.73271, 58.96564],
    enable_insight: true
};
