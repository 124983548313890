export enum BikeStatus {
    DockedWithNoError = 1,
    DockedWithError = 2,
    LockedWithNoError = 3,
    LockedWithError = 4,
    Disabled = 5,
    DisabledLoose = 10,
    LooseBikeWithNoError = 6,
    LooseBikeWithError = 7,
    DisabledWithTesting = 8,
    DisabledWithMoving = 9,
    DisableToWorkshop = 11,
    NormalInSession = 12,
    ErrorBikesInSession = 13,
    RepairFinished = 14,
    NormalPassiveSession = 15,
    ErrorPassiveSession = 16,
    DisabledInWorkshop = 17,
    DisabledInStorage = 18,
    DisabledCheckedNeedRepair = 19,
    DisabledWithStreetTeam = 20,
    DisabledOnLoan = 21
}