export enum TranslateMessageTypes {

    LockSuccess,
    LockFail,
    UnlockSuccess,
    UnlockFail,
    Disable,
    Enable,
    TransportationSuccessCreatedPickedUp,
    TransportationSuccessCreatedPickedUpError,
    TransportationSuccessPickedUp,
    TransportationSuccessPickedUpError,
    TransportationExist,
    DaysAgo,
    Never,
    BikeServiceGetError,
    BikeServiceAddSuccess,
    BikeServiceAddFail,
    UndockAndUnlockFail,
    ErrorReportedSuccess,
    ErrorReportedFail,
    RepairRegisterSuccess,
    RepairRegisterSaved,
    RepairRegisterFail,
    // AlertAcknowledgedSuccess,
    // AlertAcknowledgedFail,
    GetAllInventoriesFail,
    NoPartsOrVariants,
    // AcknowledgeTransportAndCreateRepairSuccess,
    // AcknowledgeTransportAndCreateRepairFail,
    MinutesAgo,
    HoursAgo,
    SecondsAgo,
    InsufficientQuantity,
    RepairUpdateSuccess,
    InventoryAddedSuccess,
    InventoryAddedFail
}